import React, { useContext } from 'react';
import { AlertContext } from './AlertContext'; // Adjust the import path as needed
import '../scss/_alert.css';
const ShowAlert = () => {
    const { alert } = useContext(AlertContext);

    if (!alert.message) return null;

    return (
        <div className={`alert alert-${alert.type}`} role="alert">
            {alert.message}
        </div>
    );
};

export default ShowAlert;
