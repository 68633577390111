import 'react-app-polyfill/stable';
import 'core-js';
import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import store from './store';
import { AlertProvider } from './components/AlertContext'; // Ensure this import path is correct

createRoot(document.getElementById('root')).render(
    <Provider store={store}>
        <AlertProvider>
            <App></App>
        </AlertProvider>
    </Provider>
);

reportWebVitals();
