import React, { Component, Suspense } from 'react';
import { HashRouter, Route, Routes } from 'react-router-dom';
import './scss/style.scss';
import { UserProvider } from './views/login/UserContext';
import ShowAlert from './components/ShowAlert';
import AuthWrapper from './components/AuthWrapper';

const loading = (
    <div className="pt-3 text-center">
        <div className="sk-spinner sk-spinner-pulse"></div>
    </div>
);

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'));

// Pages
const Login = React.lazy(() => import('./views/login/Login'));
const RequestResetPasswortd = React.lazy(
    () => import('./views/login/RequestResetPassword')
);

const ResetPassword = React.lazy(() => import('./views/login/ResetPassword'));

const Register = React.lazy(() => import('./views/login/Register'));

class App extends Component {
    render() {
        return (
            <UserProvider>
                <HashRouter>
                    <Suspense fallback={loading}>
                        <ShowAlert />
                        <Routes>
                            <Route
                                path="*"
                                name="Home"
                                element={
                                    <AuthWrapper>
                                        <DefaultLayout />
                                    </AuthWrapper>
                                }
                            />
                            <Route
                                exact
                                path="/login"
                                name="Login Page"
                                element={<Login />}
                            />
                            <Route
                                exact
                                path="/requestPasswordReset"
                                name="Reset Password"
                                element={<RequestResetPasswortd />}
                            />
                            <Route
                                exact
                                path="/passwordReset"
                                name="Reset Password"
                                element={<ResetPassword />}
                            />
                            <Route
                                exact
                                path="/register"
                                name="Register"
                                element={<Register />}
                            />
                        </Routes>
                    </Suspense>
                </HashRouter>
            </UserProvider>
        );
    }
}

export default App;
