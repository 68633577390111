import React from 'react';
import { Navigate } from 'react-router-dom';
import { useUser } from '../views/login/UserContext';
import { CSpinner } from '@coreui/react'; // Assuming you're using CoreUI for loading spinner

const AuthWrapper = ({ children }) => {
    const { user, loading } = useUser();

    if (loading) {
        // Show a spinner or a loading placeholder while validating session
        return <CSpinner />;
    }

    if (!user) {
        // User not logged in, redirect to login page
        return <Navigate to="/login" replace />;
    }

    return children;
};

export default AuthWrapper;
