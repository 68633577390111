import React, { createContext, useState, useContext } from 'react';

// AlertContext.js
export const AlertContext = createContext();

export const AlertProvider = ({ children }) => {
    const [alert, setAlert] = useState({ message: '', type: '' });

    const showAlert = (message, type = 'success') => {
        setAlert({ message, type });
        setTimeout(() => {
            setAlert({ message: '', type: '' });
        }, 5000); // Alert disappears after 5 seconds
    };

    return (
        <AlertContext.Provider value={{ alert, showAlert }}>
            {children}
        </AlertContext.Provider>
    );
};
